import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'
import { Page, Button, Forbidden } from '../../components'
import { FormUtil } from '../../shared'
import mainStyles from '../../assets/css/App.module.scss'
import styles from './AccountList.module.scss'
import { useAccountDispatch, useAccountState } from '../../contexts/account'
import { StringUtil } from '../../shared'
import { SearchInput } from './SearchInput'
import { DEFAULT_COUNTRY } from '../../shared/form'
import { AsYouType } from 'libphonenumber-js/max'
import PoliAccountUtil from '../../shared/poli'

const MIN_KEYWORDS_LENGTH = 3

function StatusTd(props) {
	const classes = [styles.statusLight]
	new Date(props.expiresAt) < new Date() && classes.push(styles.inactive)
	return <td className={classes.join(' ')}>{props.children}</td>
}

function AccountLink(props) {
	return (
		<Link className={mainStyles.link} to={`/poli/account/save/${props.id}`}>
			{props.label}
		</Link>
	)
}

export const PoliAccountList = () => {
	const [state, setState] = useState({})
	const [results, setResults] = useState()
	const accountState = useAccountState()
	const accountDispatch = useAccountDispatch()
	const isLoaded = accountState.isInitialized

	function setKeywords(keywords) {
		accountDispatch({
			type: 'update',
			payload: {
				data: {
					accountListKeywords: keywords
				}
			}
		})
	}

	async function preFetch() {
		const account = new PoliAccountUtil()
		const { users } = await account.list({
			orderBy: 'name'
		})

		setState({
			fetchedData: users
		})

		return users
	}

	function renderData() {
		const data = state.fetchedData ?? null

		if (!isLoaded) return null

		if (data === null || !data.length) {
			return <p>No accounts were found.</p>
		}

		return (
			<>
				<div style={{ marginBottom: 40 }}>
					<SearchInput focusOnLoad onChange={setKeywords} />
				</div>

				{results?.length ? (
					<table>
						<thead>
							<tr>
								<td>Name</td>
								<td>E-mail</td>
								<td style={{ width: 30 }}>Language</td>
								<td>Expire At</td>
								<td>Created At</td>
							</tr>
						</thead>
						<tbody className={mainStyles.smallFont}>
							{results.map(item => {
								return (
									<tr key={item.id}>
										<StatusTd expiresAt={item.expires_at}>
											<AccountLink id={item.id} label={item.name} />
										</StatusTd>
										<td className={mainStyles.noBreak}>
											{item.email ? (
												<AccountLink id={item.id} label={item.email} />
											) : (
												<>&mdash;</>
											)}
										</td>
										<td className={mainStyles.noBreak}>
											{item.user_language ? item.user_language : <>&mdash;</>}
										</td>
										<td className={mainStyles.noBreak}>
											{FormUtil.formatDate(new Date(item.expires_at))}
										</td>
										<td className={mainStyles.noBreak}>
											{item.created_at ? (
												FormUtil.formatDate(new Date(item.created_at))
											) : (
												<>&mdash;</>
											)}
										</td>
									</tr>
								)
							})}
							<tr />
						</tbody>
					</table>
				) : null}

				{accountState.data?.accountListKeywords?.length >=
					MIN_KEYWORDS_LENGTH && !results?.length ? (
					<p>Sorry, no accounts found. Try a different search criteria.</p>
				) : null}
			</>
		)
	}

	useEffect(() => {
		if (
			!state.fetchedData ||
			!accountState.data ||
			!accountState.data.accountListKeywords ||
			accountState.data.accountListKeywords?.length < MIN_KEYWORDS_LENGTH
		) {
			setResults([])
			return
		}

		let parsedKeywords = accountState.data.accountListKeywords
		const options = {
			threshold: 0.1,
			minMatchCharLength: MIN_KEYWORDS_LENGTH,
			keys: ['name', 'email', 'mobile_number'],
			getFn: function (obj, path) {
				const value = Fuse.config.getFn(obj, path)

				if (Array.isArray(value)) {
					return value.map(el => StringUtil.normalize(el))
				}

				return StringUtil.normalize(value)
			}
		}

		if (parsedKeywords.match(/@/)) options.keys = ['email']
		else if (parsedKeywords.match(/\+|[0-9]+/)) {
			const asYouType = new AsYouType(DEFAULT_COUNTRY)
			parsedKeywords = asYouType
				.input(parsedKeywords)
				.replace(/[()]/g, '')
				.replace(/-/, ' ')
			options.keys = ['mobile_number']
		}

		const fuse = new Fuse(state.fetchedData, options)

		setResults(
			fuse
				.search(StringUtil.normalize(parsedKeywords))
				.map(result => result.item)
				.slice(0, 10)
		)
	}, [accountState.data?.accountListKeywords, state.fetchedData])

	if (!isLoaded) return null

	return (
		<Page
			preFetch={preFetch}
			render={pageState => {
				if (!pageState.authData.account.permissions?.includes('accountList')) {
					return <Forbidden />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								<h3>
									Poli Accounts
									{pageState.authData.account.permissions?.includes(
										'poliAccountAdd'
									) ? (
										<span>
											<Button size="small" to="/poli/account/save">
												Create
											</Button>
										</span>
									) : null}
								</h3>
								{renderData()}
							</div>
						</div>
					</>
				)
			}}
		/>
	)
}
